import './App.css';
import Menu from './components/Menu.jsx';
import AnnouncementBar from './components/AnnouncementBar';

function App() {
  return (
    <div className="App">
      <AnnouncementBar />
      <Menu />
    </div>
  );
}

export default App;
