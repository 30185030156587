import React from 'react';
import classes from './AnnouncementBar.module.css'

const AnnouncementBar = () => {
    return (
        <div className={classes.AnnouncementBar}>
            <div className={classes.AnnouncementBar_inner}>
                Support Ukraine 🇺🇦 <a target="_blank" rel="noopener noreferrer" href="https://opensource.facebook.com/support-ukraine">
                    Help Provide Humanitarian Aid to Ukraine
                    </a>
            </div>
        </div>
    )
}

export default AnnouncementBar;