import classes from './Menu.module.css';
import React from 'react';
import {
    Route,
    Routes,
    NavLink,
    HashRouter
} from "react-router-dom";
import Home from "./Home";
import Information from "./Information";
import {Contacts, address, sendEmail} from "./Contacts";


const Menu = () => {
    return (
        <HashRouter>
            <h1>.rq Lab</h1>
            <div className={classes.Menu}>
                <ul className={classes.Menu_header}>
                    <li><NavLink to="/">Home</NavLink></li>
                    <li><NavLink to="/information">Information</NavLink></li>
                    <li><NavLink to="/contacts">Contacts</NavLink></li>
                </ul>
                <div className={classes.Content}>
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/information" element={<Information />}/>
                        <Route path="/contacts" element={<Contacts />}/>
                    </Routes>
                </div>
                <div className={classes.Footer}>
                    © 2022 .rq Lab. Business inquiries: 
                    <a href="#" className="cryptedmail"
                                data-name={address[0]}
                                data-domain={address[2]+address[3]}
                                data-tld={address[5]}
                                onClick={sendEmail}></a>
                </div>
            </div>
        </HashRouter>
    )
}

export default Menu;