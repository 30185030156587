import React from 'react';

const Information = () => {
    return (
        <div>
            <h2>Information</h2>
            <p>Recently developed projects are:</p>
            <p>Was involved in Boltzman Lattice implementation for TurbulentDynamics, optimized for Cuda: <a href="https://github.com/TurbulentDynamics/tdLBCpp" target="_blank" rel="noopener noreferrer">tdLBCpp on GitHub</a></p>
            <p>Change Audio device plugin for Firefox: <a href="https://addons.mozilla.org/en/firefox/addon/chaudev" target="_blank" rel="noopener noreferrer">Chaudev at Mozilla Add-Ons</a></p>
            <p><a href="/WebGL/RollerMadness/" target="_blank" rel="noopener noreferrer">Simple Unity Game</a></p>
            <p>Many more NDA projects were developed as well</p>
            <p>Please check <a href="/#/contacts">Contacts</a> for communication</p>
        </div>
    )
}

export default Information;
