import React from 'react';
import Button from './Button';

const address = ['business', '@', 'roaming', 'quark', '.', 'info']

function createAddress() {
  return address.join('');
}

function sendEmail() {
  window.open('mailto:' + createAddress() + '?subject=Business Inquiry&body=Hi', '_self')
}

const Contacts = () => {
    return (
        <div>
            <h2>Contacts</h2>
            <p><Button onClick={sendEmail}>Send E-mail</Button></p>
            <p><a className="App-link" href="https://www.linkedin.com/in/andreygalysh/" target="_blank" rel="noopener noreferrer">LinkedIn</a></p>
            <p><a className="App-link" href="https://github.com/theang" target="_blank" rel="noopener noreferrer">GitHub</a></p>
        </div>
    )
}

export default Contacts;
export {address, sendEmail, Contacts};