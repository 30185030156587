import React from 'react';

const Home = () => {
    return (
        <div>
            <h2>Welcome to .rq Lab</h2>
            <p>Providing services for Automation and Software development in various areas.</p>
            <p>Currently this project is one person effort: Andrei Galysh, please <a href="/#/contacts">reach out</a> if you have any business inquiry</p>
            <p>Please check <a href="/#/information">Information</a> and <a href="/#/contacts">Contacts</a> for further clarifications.</p>
        </div>
    )
}

export default Home;